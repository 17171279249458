var jQuery = require('jquery');
var _ = require('underscore');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.tooltips = (function ($, _) {
    "use strict";
    var transitionEvent = AG.utilities.whichTransitionEvent(),
        $_agTooltips;

    var addTooltip = function (tooltipObj) {
        var ttTitleTemplate = "",
            ttTemplate,
            $ttElem;

        if (tooltipObj.title.trim().length !== 0) {
            ttTitleTemplate = '<div class="ag-tooltip__title">' + tooltipObj.title + '</div>';
        }

        var themeClass = tooltipObj.theme !== '' ? ' ag-tooltip--' + tooltipObj.theme : '';

        ttTemplate = '<div class="ag-tooltip' + themeClass + '" data-ag-tooltip-id="' + tooltipObj.tooltipId + '">' +
            '<i class="agi agi-times-c js-close-ag-tooltip"></i>' +
            ttTitleTemplate +
            '<div class="ag-tooltip__content">' + tooltipObj.content + '</div>' +
            '</div>';
        $ttElem = $(ttTemplate);

        $("body").append($ttElem);
        return $ttElem;
    };


    var getTitle = function ($el) {
        // if using custom title symbols, only grab text from the initial node
        return $el.hasClass('ag-tooltip-trigger--custom') ? $el.contents().filter(function () { return this.nodeType == 3; }).text() : $el.text();
    };

    var openTooltip = function (tooltipTrigger, themeClass) {

        var randomId = Math.floor(Math.random() * 1000),
            $tooltip;

        $(".ag-tooltip").removeClass("is-open");

        $(tooltipTrigger).attr("data-ag-tooltip-id", randomId);

        $tooltip = addTooltip({
            tooltipId: randomId,
            title: getTitle($(tooltipTrigger)),
            content: $(tooltipTrigger).attr("data-tooltip-content"), // fix: update with latest tooltip content
            theme: themeClass
        });

        positionTooltip($tooltip);
        $tooltip.addClass("is-open");
    };

    var closeTooltips = function ($elements) {
        var $ttToggle,
            tooltipId;
        if ($elements === undefined) {
            $elements = $(".ag-tooltip");
            $ttToggle = $("[data-toggle='ag-tooltip']");
        } else {
            tooltipId = $elements.data("ag-tooltip-id");
            $ttToggle = $("[data-toggle='ag-tooltip'][data-ag-tooltip-id='" + tooltipId + "']");
        }

        $elements.removeClass("is-open");
        $ttToggle.removeAttr("data-ag-tooltip-id");
        if (transitionEvent !== undefined) {
            $elements.find(".agi").one(transitionEvent, function (event) {
                event.stopPropagation();
            });

            $elements.one(transitionEvent, function () {
                $elements.remove();
            });
        } else {
            $elements.remove();
        }
    };

    var positionTooltip = function ($tooltip) {
        var tooltipId = $tooltip.data("ag-tooltip-id"),
            tooltipTrigger = $(".ag-tooltip-trigger[data-ag-tooltip-id='" + tooltipId + "']")[0],
            tooltipPadding = +$tooltip.css("padding-left").replace(/px/g, ""),
            tooltipCSS = {};

        if (AG.utilities.isMobile()) {
            tooltipCSS.left = "";
        } else {
            if (tooltipTrigger.getBoundingClientRect().left + $tooltip.outerWidth() < window.innerWidth) {
                $tooltip.removeClass("ag-tooltip--near-right");
                tooltipCSS.left = tooltipTrigger.getBoundingClientRect().left;
            } else {
                $tooltip.addClass("ag-tooltip--near-right");
                tooltipCSS.left = tooltipTrigger.getBoundingClientRect().right - ($tooltip.outerWidth() - tooltipPadding / 4);
            }
        }

        tooltipCSS.top = $(tooltipTrigger).offset().top - tooltipPadding;
        $tooltip.css(tooltipCSS);
    };

    var initTooltips = function () {

        $_agTooltips = $("[data-toggle='ag-tooltip']");

        // Only activate if there are tooltips on the page.        
        if ($_agTooltips.length < 1) return;


        $_agTooltips.off("click").on("click", function () {
            var themeClass = $(this).attr('data-tooltip-theme') !== undefined ? $(this).data('tooltip-theme') : '';
            openTooltip($(this)[0], themeClass);
        });

        $("body").on("click", ".js-close-ag-tooltip", function () {
            var $closestTooltip = $(this).closest(".ag-tooltip");
            closeTooltips($closestTooltip);
        });

        $("body").on("click", function (event) {
            if ($(event.target).closest(".ag-tooltip, [data-toggle='ag-tooltip']").length === 0) {
                closeTooltips();
            }
        });

        var _positionTooltips = _.debounce(function () {
            $(".ag-tooltip").each(function (i, e) {
                positionTooltip($(e));
            });
        }, 100);

        $(window).on("resize", function () {
            _positionTooltips();
        });

    }



    return {
        init: initTooltips
    }


}(jQuery, _));